import React, { memo, useMemo, lazy, Suspense, useEffect } from "react";
import { Loading } from "components/shared";
import { useSelector, useDispatch } from "react-redux";
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from "constants/theme.constant";
import useAuth from "utils/hooks/useAuth";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
  [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
  [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
  [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
};

import { onSignInSuccess } from "store/auth/sessionSlice";
import { auth, onIdTokenChanged } from "utils/hooks/firebase";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();

  const layoutType = useSelector((state) => state.theme.layout.type);

  const { authenticated } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        dispatch(onSignInSuccess(token));
      }
    });

    // Clean-up function
    return () => unsubscribe(); // Unsubscribe from the observer when the component is unmounted
  }, [dispatch]);

  useDirection();

  useLocale();

  const AppLayout = useMemo(() => {
    if (location.pathname === "/ai/pip") {
      return layouts[layoutType];
    }
    if (!authenticated) {
      if (
        location.pathname.startsWith("/dream-comics/view/") ||
        location.pathname.startsWith("/imposter-game/onboarding") ||
        location.pathname.startsWith("/imposter-game/eviloverlordgabo")
      ) {
        return layouts[LAYOUT_TYPE_BLANK];
      }

      if (location.pathname.startsWith("/dream-comics")) {
        return layouts[LAYOUT_TYPE_BLANK];
      }
    }

    if (authenticated) {
      return layouts[layoutType];
    }
    return lazy(() => import("./AuthLayout"));
  }, [location.pathname, authenticated, layouts, layoutType]);

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout />
    </Suspense>
  );
};

export default memo(Layout);
