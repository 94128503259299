import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
    name: 'chat/state',
    initialState: {
        chatHistory: [],
        chatHistoryLoading: false,
        friendName: null,
        friends: [],
        friendsLoading: true,
        friendId: null,
        friend: null,
    },

    reducers: {
        setChatHistory: (state, action) => {
            state.chatHistory = action.payload
        },

        setChatHistoryLoading: (state, action) => {
            state.chatHistoryLoading = action.payload
        },

        setFriend: (state, action) => {
            state.friend = action.payload
        },

        setFriendName: (state, action) => {
            state.friendName = action.payload
        },

        setFriends: (state, action) => {
            state.friends = action.payload
        },

        setFriendsLoading: (state, action) => {
            state.friendsLoading = action.payload
        },

        setFriendId: (state, action) => {
            state.friendId = action.payload
        }
    }
})

export const {
    setChatHistory,
    setChatHistoryLoading,
    setFriendName,
    setFriends,
    setLoading,
    setFriendId,
    setFriend
} = chatSlice.actions

export default chatSlice.reducer