import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, push, onDisconnect, remove, set, serverTimestamp } from "firebase/database";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useUserPresence = () => {
  const userInfo = useSelector((state) => state.auth.user);
  const userId = userInfo?.id;
  const location = useLocation(); // React Router's useLocation hook
  const db = getDatabase();
  const [prevPage, setPrevPage] = useState('');

  useEffect(() => {
    const myConnectionsRef = ref(db, `users/${userId}/connections`);
    const lastOnlineRef = ref(db, `users/${userId}/lastOnline`);
    const connectedRef = ref(db, '.info/connected');
    const con = push(myConnectionsRef);

    const pageParts = location.pathname.split('/').map(part => {
      if (part) {
        return `-${part}`; // Add "-" prefix to each part
      }
      return part;
    });

    const onlineUsersByPageRef = ref(db, `onlineUsersByPage/${pageParts.join('/')}/${userId}`);
    const conByPage = push(onlineUsersByPageRef);

    // Remove user from the previous page immediately when moving to a new page
    if (prevPage && prevPage !== location.pathname) {
      console.log("Removing from Previous Page:", prevPage);
      const previousPageParts = prevPage.split('/').map(part => {
        if (part) {
          return `-${part}`; // Add "-" prefix to each part
        }
        return part;
      });
      const previousPageRef = ref(db, `onlineUsersByPage${previousPageParts.join('/')}/${userId}`);
      remove(previousPageRef);
    }

    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        onDisconnect(con).remove();
        set(con, true);
        onDisconnect(lastOnlineRef).set(serverTimestamp());

        setPrevPage(location.pathname);

        // Set user's online status for the current page
        set(conByPage, true);
      } else {
        set(conByPage, false);
      }
    });

    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        onDisconnect(conByPage).remove();
        set(conByPage, true);
      } else {
        set(conByPage, false);
      }
    });

    return () => {
      set(con, false);
      set(conByPage, false);
    };
  }, [location]); // Update when location changes
};

export default useUserPresence;
