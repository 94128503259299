import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import mockServer from "./mock";
import appConfig from "configs/app.config";
import "./locales";
import UseUserPresence from "./userPresence";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import Smartlook from "smartlook-client";

const environment = process.env.NODE_ENV;

if (environment === "production") {
  ReactGA.initialize("G-EVD427JGC9");
  Smartlook.init(process.env.REACT_APP_DREAMBOX_SMARTLOOK_KEY, {
    region: "eu",
  });
}
/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== "production" && appConfig.enableMock) {
  mockServer({ environment });
}

const cacheTime12H = 1000 * 60 * 60 * 12;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: cacheTime12H,
      enabled: true,
    },
    mutations: {
      cacheTime: cacheTime12H,
      enabled: true,
    },
  },
});
const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  //useUserPresence();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <BrowserRouter history={history}>
            <Theme>
              <UseUserPresence />
              <Layout />
            </Theme>
          </BrowserRouter>
        </PersistQueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
