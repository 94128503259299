import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState, setDiscordUser } from "store/auth/userSlice";
import { useCookies } from "react-cookie";
import {
  apiSignIn,
  apiSignUp,
  apiGetUser,
  apiGoogleSignIn,
  apiGetAiGamesForUser,
  apiDeleteAiGame,
  apiCreateAiGame,
  apiDiscordSignIn,
  apiDiscordLink,
  apiTestGameAPI,
  apiTestGameAssetAPI,
  apiTestRembgApi,
  apiGetFriends,
  apiGetChatHistory,
  apiSendAiChatMessage,
  apiSendChatMessage,
  apiLeaveRpgLobbyRequest,
  apiMarkChatAsRead,
  apiIncrementGameView,
  apiGetGames,
  apiGetTopEventUsers,
  apiStatsGetTopRpgUsers,
  apiStatsGetTopChatSent,
  apiStatsGetTopChatReceived,
  apiStatsGetTopPages,
  apiGenerateImage,
  apiAiNewCharacter,
  apiGetAiCharacters,
  apiGetAiCharacter,
  apiSaveAvatar,
  apiGetFeed,
  apiGetProfile,
  apiGroupChat,
  apiGetAiVsAiChatList,
  apiGetAiVsAiChatHistory,
  apiSendAiVsAiMessage,
  apiCreateAiVsAiChat,
  apiSendReactionToBackend,
  apiSendEventReaction,
  apiSendLike,
  apiGetLike,
  apiGetEventReactions,
  apiGetEventData,
  apiGetSingleCommunityData,
  apiGetCommunityData,
  apiGetTopCommunityData,
  apiGetMyCommunityData,
  apiLeaveCommunity,
  apiJoinCommunity,
  apiBoxingSendMessage,
  apiBoxingJoinGame,
  apiBoxingReset,
  apiCreateCommunity,
  apiCreateAchievement,
  apiGetAchievements,
  apiGetWordGameDetails,
  apiSendWordGameMessage,
  apiGetWordGameLeaderboard,
  apiUseReferralCode,
  apiGetWordGameRooms,
  apiCreateWordGameRoom,
  apiDndListGames,
  apiDndFetchGame,
  apiDndJoinGame,
  apiDndSendMessage,
  apiDndGenerateImages,
  apiDndCreateGame,
  apiDndUpdateGame,
  apiDndLikeGame,
  apiVoteWordGameTheme,
  apiVoteWordGameSkip,
  apiGetDreamPets,
  apiGetAdoptTickets,
  apiGetAdoptableDreamPets,
  apiGetDreamPet,
  apiAdoptDreamPet,
  apiGenerateDreamPet,
  apiSendDreampetGroupChat,
  apiPetAction,
  apiGetUserActivityMap,
  apiQuickJoinPetBattle,
  apiAiJoinPetBattle,
  apiPetBattleAction,
  apiGetPetBattleLobbyInfo,
  apiGetPetBattleStats,
  apiCgCreateRoom,
  apiCgFetchRooms,
  apiCgPlayerReady,
  apiCgJoinGame,
  apiCgPlayCard,
  apiCgDeleteCard,
  apiSendDreampetBattleChat,
  apiSendDreampetBossBattleChat,
  apiGetOngoingDreampetBattles,
  apiRequest,
  apiGetDreampetShopAbilites,
  apiGetBattlePoints,
  apiBuyDreampetShopAbility,
  apiReplacePetAbility,
  apiQuickJoinPetBossBattle,
  apiGetPetBossBattleLobbyInfo,
  apiPetBossBattleAction,
  apiClaimPetBossBattleReward,
  apiGetPetBossBattleRewards,
  apiGetPetBossBattleCommunityRoom,
  apiGetExperienceData,
} from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

import { auth } from "utils/hooks/firebase";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithCustomToken,
  GoogleAuthProvider,
} from "firebase/auth";

function useAuth(isOnboarding = false) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const provider = new GoogleAuthProvider();

  const [cookies, setCookie, removeCookie] = useCookies([REDIRECT_URL_KEY]);

  const firebaseGoogleContinue = async () => {
    console.log("google continue");
    if (signedIn) {
      console.log("already signed in");
      return;
    }

    return signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        if (result.user) {
          const accessToken = result.user.accessToken;

          await getUserProfile(accessToken);
          dispatch(onSignInSuccess(accessToken));

          // wait for 2 seconds
          setTimeout(() => {
            let redirectUrl = query.get(REDIRECT_URL_KEY);
            if (isOnboarding) return;

            if (redirectUrl) {
              console.log("google redirect from query");

              navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
              );
            } else {
              if (cookies[REDIRECT_URL_KEY]) {
                console.log("google redirect from cookkie");
                redirectUrl = cookies[REDIRECT_URL_KEY];
                alert(redirectUrl);
                removeCookie(REDIRECT_URL_KEY, {
                  path: "/",
                  maxAge: 3600, // Expires after 1hr
                  sameSite: true,
                });
                console.log("redirecting to: ", redirectUrl);
                navigate(redirectUrl);
              }
            }
            return {
              status: "success",
              message: "",
            };
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("error", error);
        return {
          status: "failed",
          message: "",
        };
      });
  };

  const discordSignIn = async (values, referral = null) => {
    try {
      console.log("discord sign in");
      if (signedIn) {
        console.log("already signed in");
        return;
      }
      const resp = await apiDiscordSignIn(values);
      if (resp.data) {
        const { authToken } = resp.data;
        // sign in with firebase
        return signInWithCustomToken(auth, authToken)
          .then(async (resp) => {
            const { accessToken } = resp.user;
            await getUserProfile(accessToken);
            dispatch(onSignInSuccess(accessToken));
            let redirectUrl = query.get(REDIRECT_URL_KEY);
            if (referral != null) {
              try {
                console.log("referral");
                useReferral(referral);
              } catch {
                console.log("error using referral");
              }
            }

            if (redirectUrl) {
              console.log("redirectUrl from query disc: ", redirectUrl);
              navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
              );
            } else {
              if (cookies[REDIRECT_URL_KEY]) {
                redirectUrl = cookies[REDIRECT_URL_KEY];
                console.log("redirecting to: ", redirectUrl);

                removeCookie(REDIRECT_URL_KEY, {
                  path: "/",
                  maxAge: 3600, // Expires after 1r
                  sameSite: true,
                });

                navigate(redirectUrl);
              } else {
                console.log("redirecting to home");
                navigate(appConfig.authenticatedEntryPath);
              }
            }

            return {
              status: "success",
              message: "",
            };
          })
          .catch((error) => {
            return {
              status: "failed",
              message: "",
            };
          });
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const discordLink = async (values) => {
    try {
      if (!signedIn) {
        console.log("must be signed in");
        navigate("/dream-date?link-status=failed"); // Redirect immediately if not signed in
        return;
      }
  
      const resp = await apiDiscordLink(values);
  
      if (resp.data) {
        console.log(resp.data);
        // Navigate regardless of success or failure
        console.log("setting discord user true")
        dispatch(setDiscordUser(true));
        navigate("/dream-date");
      }
      
      
    } catch (errors) {
      const errorMessage =
        errors?.response?.data?.message || errors.toString();
      console.error("Error linking Discord:", errorMessage);
  
      // Navigate with query param on failure
      
      navigate("/dream-date?link-status=failed");
      return {
        status: "failed",
        message: errorMessage,
      };
    }
  };
  
  const googleSignIn = async (values) => {
    if (signedIn) {
      console.log("already signed in");
      return;
    }
    try {
      console.log("google sign in");
      const resp = await apiGoogleSignIn(values);
      if (resp.data) {
        const { authToken } = resp.data;
        await getUserProfile(authToken);
        dispatch(onSignInSuccess(authToken));
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signIn = async (email, password) => {
    if (signedIn) {
      console.log("already signed in");
      return;
    }
    try {
      const resp = await signInWithEmailAndPassword(auth, email, password);
      if (resp.user) {
        const { accessToken } = resp.user;
        await getUserProfile(accessToken);
        dispatch(onSignInSuccess(accessToken));
        if (isOnboarding) {
          cb && cb();
          return;
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      let error = errors?.response?.data?.message || errors.toString();
      if (error.includes("user-not-found")) {
        error = "Email not found";
      }
      if (error.includes("wrong-password")) {
        error = "Password is incorrect";
      }
      return {
        status: "failed",
        message: error,
      };
    }
  };

  const signUp = async (email, password) => {
    try {
      const resp = await createUserWithEmailAndPassword(auth, email, password);
      if (resp.user) {
        const { accessToken } = resp.user;
        await getUserProfile(accessToken);
        dispatch(onSignInSuccess(accessToken));
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      let error = errors?.response?.data?.message || errors.toString();
      if (error.includes("already-in-use")) {
        error = "Email already in use";
      }
      return {
        status: "failed",
        message: error,
      };
    }
  };

  const handleSignOut = async () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath + "?fromSignedIn=true");
  };

  const signOut = async () => {
    await auth.signOut();
    await handleSignOut();
  };

  const getUserProfile = async (token) => {
    if (token) {
      const userData = await apiGetUser(token);
      if (userData) {
        dispatch(
          setUser({
            id: userData.data.id,
            userName: "",
            authority: userData.data.groups,
            email: userData.data.email,
            name: userData.data.name,
            created_at: userData.data.created_at,
            avatar: userData.data.avatar,
          })
        );
      }
    }
  };
  const useReferral = async (referralCode) => {
    const result = await useReferralCode(referralCode);
  };

  const createAiGame = async (data) => {
    try {
      const resp = await apiCreateAiGame(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getUserActivityMap = async () => {
    try {
      const resp = await apiGetUserActivityMap();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getUserAiGames = async (data) => {
    try {
      const resp = await apiGetAiGamesForUser(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const useReferralCode = async (code) => {
    try {
      const resp = await apiUseReferralCode(code);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getDreamPets = async (data) => {
    try {
      const resp = await apiGetDreamPets(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getAdoptableDreamPets = async () => {
    try {
      const resp = await apiGetAdoptableDreamPets();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getAdoptTickets = async () => {
    try {
      const resp = await apiGetAdoptTickets();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getDreamPet = async (pet_id) => {
    try {
      const resp = await apiGetDreamPet(pet_id);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const adoptDreamPet = async (pet_id) => {
    try {
      const resp = await apiAdoptDreamPet(pet_id);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const generateDreamPet = async (data) => {
    try {
      const resp = await apiGenerateDreamPet(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const petAction = async (data) => {
    try {
      const resp = await apiPetAction(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const quickJoinPetBattle = async (data) => {
    try {
      const resp = await apiQuickJoinPetBattle(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const quickJoinPetBossBattle = async (data) => {
    try {
      const resp = await apiQuickJoinPetBossBattle(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const aiJoinPetBattle = async (data) => {
    try {
      const resp = await apiAiJoinPetBattle(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const petBattleAction = async (data) => {
    try {
      const resp = await apiPetBattleAction(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const petBossBattleAction = async (data) => {
    try {
      const resp = await apiPetBossBattleAction(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const claimPetBossBattleReward = async (lobbyId) => {
    try {
      const resp = await apiClaimPetBossBattleReward(lobbyId);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getPetBossBattleRewards = async () => {
    try {
      const resp = await apiGetPetBossBattleRewards();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getPetBossBattleCommunityRoom = async (communityId) => {
    try {
      const resp = await apiGetPetBossBattleCommunityRoom(communityId);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getPetBattleLobbyInfo = async (lobbyId) => {
    try {
      const resp = await apiGetPetBattleLobbyInfo(lobbyId);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getPetBossBattleLobbyInfo = async (lobbyId) => {
    try {
      const resp = await apiGetPetBossBattleLobbyInfo(lobbyId);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getOngoingDreampetBattles = async (sort) => {
    try {
      const resp = await apiGetOngoingDreampetBattles(sort);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getPetBattleStats = async (data) => {
    try {
      const resp = await apiGetPetBattleStats(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getDreampetShopAbilites = async (sort) => {
    try {
      const resp = await apiGetDreampetShopAbilites(sort);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getBattlePoints = async () => {
    try {
      const resp = await apiGetBattlePoints();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const buyDreampetShopAbility = async (abilityId) => {
    try {
      const resp = await apiBuyDreampetShopAbility(abilityId);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const replacePetAbility = async (data) => {
    try {
      const resp = await apiReplacePetAbility(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getWordGameDetails = async (room) => {
    try {
      const resp = await apiGetWordGameDetails(room);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getWordGameRooms = async () => {
    try {
      const resp = await apiGetWordGameRooms();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const createWordGameRoom = async (data) => {
    try {
      const resp = await apiCreateWordGameRoom(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getWordGameLeaderboard = async (room) => {
    try {
      const resp = await apiGetWordGameLeaderboard(room);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const sendWordGameMessage = async (data) => {
    try {
      const resp = await apiSendWordGameMessage(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const deleteAiGame = async (id) => {
    try {
      const resp = await apiDeleteAiGame(id);
      return {
        status: "success",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const testGameApi = async () => {
    try {
      const resp = await apiTestGameAPI();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const testGameAssetAPI = async () => {
    try {
      const resp = await apiTestGameAssetAPI();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const testRembgApi = async () => {
    try {
      const resp = await apiTestRembgApi();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getFriends = async (user_name, get_humans, selected_friend) => {
    try {
      const resp = await apiGetFriends(user_name, get_humans, selected_friend);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getChatHistory = async (user1, user2) => {
    try {
      const resp = await apiGetChatHistory(user1, user2);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const sendAiChatMessage = async (data) => {
    try {
      const resp = await apiSendAiChatMessage(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const sendChatMessage = async (data) => {
    try {
      const resp = await apiSendChatMessage(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const createCommunity = async (data) => {
    try {
      const resp = await apiCreateCommunity(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const createAchievement = async (data) => {
    try {
      const resp = await apiCreateAchievement(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const sendLeaveRpgLobbyRequest = async (rpgUsername, rpgCode) => {
    try {
      const resp = await apiLeaveRpgLobbyRequest(rpgUsername, rpgCode);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const markChatAsRead = async (data) => {
    try {
      const resp = await apiMarkChatAsRead(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getGames = async () => {
    try {
      const resp = await apiGetGames();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getTopEventUsers = async (data) => {
    try {
      const resp = await apiGetTopEventUsers(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const incrementGameView = async (game_id) => {
    try {
      const resp = await apiIncrementGameView(game_id);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const statsGetTopRpgUsers = async () => {
    try {
      const resp = await apiStatsGetTopRpgUsers();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const statsGetTopChatSent = async () => {
    try {
      const resp = await apiStatsGetTopChatSent();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const statsGetTopChatReceived = async () => {
    try {
      const resp = await apiStatsGetTopChatReceived();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const statsGetTopPages = async () => {
    try {
      const resp = await apiStatsGetTopPages();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getExperienceData = async (data) => {
    try {
      const resp = await apiGetExperienceData(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const generateAvatar = async (data) => {
    try {
      const resp = await apiGenerateImage(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const aiNewCharacter = async (data) => {
    try {
      const resp = await apiAiNewCharacter(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const aiGetCharacters = async (username = null) => {
    try {
      const resp = await apiGetAiCharacters(username);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const aiGetCharacter = async (permalink_or_id) => {
    try {
      const resp = await apiGetAiCharacter(permalink_or_id);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const saveAvatar = async (data) => {
    try {
      const resp = await apiSaveAvatar(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getFeeds = async (start_after) => {
    try {
      const resp = await apiGetFeed(start_after);
      return {
        status: "success",
        data: {
          feeds: resp.data.feeds,
          last_id: resp.data.last_id,
        },
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const sendGroupMessage = async (payload) => {
    try {
      const resp = await apiGroupChat(payload);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const sendDreampetGroupChat = async (payload) => {
    try {
      const resp = await apiSendDreampetGroupChat(payload);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const sendDreampetBattleChat = async (payload) => {
    try {
      const resp = await apiSendDreampetBattleChat(payload);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const sendDreampetBossBattleChat = async (payload) => {
    try {
      const resp = await apiSendDreampetBossBattleChat(payload);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const voteWordGameTheme = async (data) => {
    try {
      const resp = await apiVoteWordGameTheme(data);
      return {
        status: "success",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const voteWordGameSkip = async (data) => {
    try {
      const resp = await apiVoteWordGameSkip(data);
      return {
        status: "success",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const sendReactionToBackend = async (payload) => {
    try {
      const resp = await apiSendReactionToBackend(payload);
      return {
        status: "success",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const sendEventReaction = async (payload) => {
    try {
      const resp = await apiSendEventReaction(payload);
      return {
        status: "success",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const sendLike = async (payload) => {
    try {
      const resp = await apiSendLike(payload);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getLike = async (payload) => {
    try {
      const resp = await apiGetLike(payload);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getEventReactions = async (event) => {
    try {
      const resp = await apiGetEventReactions(event);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getEventData = async (event) => {
    try {
      const resp = await apiGetEventData(event);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getCommunityData = async () => {
    try {
      const resp = await apiGetCommunityData();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getSingleCommunityData = async (communityId) => {
    try {
      const resp = await apiGetSingleCommunityData(communityId);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getTopCommunityData = async () => {
    try {
      const resp = await apiGetTopCommunityData();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getMyCommunityData = async (uid) => {
    try {
      const resp = await apiGetMyCommunityData(uid);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getProfileData = async (uid) => {
    try {
      const resp = await apiGetProfile(uid);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const leaveCommunity = async (community_name) => {
    try {
      const resp = await apiLeaveCommunity(community_name);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const joinCommunity = async (community_name) => {
    try {
      const resp = await apiJoinCommunity(community_name);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getAchievements = async () => {
    try {
      const resp = await apiGetAchievements();
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };
  const getAiVsAiChatList = async (user_id) => {
    try {
      const resp = await apiGetAiVsAiChatList(user_id);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getAiVsAiChatHistory = async (ai_vs_ai_chat_id) => {
    try {
      const resp = await apiGetAiVsAiChatHistory(ai_vs_ai_chat_id);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const sendAiVsAiMessage = async (payload) => {
    try {
      const resp = await apiSendAiVsAiMessage(payload);
      return {
        status: "success",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const createNewAiVsAiChat = async (payload) => {
    try {
      const resp = await apiCreateAiVsAiChat(payload);
      if (resp.data.success === true) {
        return {
          status: "success",
          data: resp.data,
        };
      } else {
        return {
          status: "failed",
          message: resp.data.message,
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const boxingJoinGame = async (data) => {
    try {
      const resp = await apiBoxingJoinGame(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const boxingSendMessage = async (data) => {
    try {
      const resp = await apiBoxingSendMessage(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const boxingReset = async (data) => {
    try {
      const resp = await apiBoxingReset(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const dndListGames = async (data) => {
    try {
      const resp = await apiDndListGames(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const dndFetchGame = async (roomId, data) => {
    try {
      const resp = await apiDndFetchGame(roomId, data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const dndJoinGame = async (roomId, data) => {
    try {
      const resp = await apiDndJoinGame(roomId, data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const dndSendMessage = async (roomId, data) => {
    try {
      const resp = await apiDndSendMessage(roomId, data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const dndGenerateImages = async (data) => {
    try {
      const resp = await apiDndGenerateImages(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const dndCreateGame = async (data) => {
    try {
      const resp = await apiDndCreateGame(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const dndUpdateGame = async (roomId, data) => {
    try {
      const resp = await apiDndUpdateGame(roomId, data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const dndLikeGame = async (roomId, data) => {
    try {
      const resp = await apiDndLikeGame(roomId, data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const cgCreateRoom = async (data) => {
    try {
      const resp = await apiCgCreateRoom(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const cgFetchRooms = async (data) => {
    try {
      const resp = await apiCgFetchRooms(data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const cgPlayerReady = async (roomId, data) => {
    try {
      const resp = await apiCgPlayerReady(roomId, data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const cgJoinGame = async (roomId, data) => {
    try {
      const resp = await apiCgJoinGame(roomId, data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const cgPlayCard = async (roomId, data) => {
    try {
      const resp = await apiCgPlayCard(roomId, data);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  // Utility function so we don't have to keep repeating the same codes
  const makeRequest = async (
    path,
    method = "get",
    data = {},
    timeout = 1 * 60 * 1000
  ) => {
    try {
      const resp = await apiRequest(path, method, data, timeout);
      return {
        status: "success",
        data: resp.data,
      };
    } catch (errors) {
      console.log(errors);
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    discordSignIn,
    discordLink,
    googleSignIn,
    getUserAiGames,
    deleteAiGame,
    createAiGame,
    testGameApi,
    testGameAssetAPI,
    testRembgApi,
    getFriends,
    getChatHistory,
    sendAiChatMessage,
    sendChatMessage,
    sendLeaveRpgLobbyRequest,
    markChatAsRead,
    getGames,
    getProfileData,
    getTopEventUsers,
    incrementGameView,
    statsGetTopRpgUsers,
    statsGetTopChatSent,
    statsGetTopChatReceived,
    statsGetTopPages,
    generateAvatar,
    aiNewCharacter,
    aiGetCharacters,
    saveAvatar,
    firebaseGoogleContinue,
    getFeeds,
    aiGetCharacter,
    sendGroupMessage,
    getAiVsAiChatList,
    getAiVsAiChatHistory,
    sendAiVsAiMessage,
    createNewAiVsAiChat,
    sendReactionToBackend,
    sendEventReaction,
    sendLike,
    getLike,
    getEventReactions,
    getEventData,
    getCommunityData,
    getSingleCommunityData,
    getTopCommunityData,
    getMyCommunityData,
    leaveCommunity,
    joinCommunity,
    boxingSendMessage,
    boxingJoinGame,
    boxingReset,
    createCommunity,
    createAchievement,
    getAchievements,
    getWordGameDetails,
    sendWordGameMessage,
    getWordGameLeaderboard,
    useReferralCode,
    getWordGameRooms,
    createWordGameRoom,
    dndListGames,
    dndFetchGame,
    dndJoinGame,
    dndSendMessage,
    dndGenerateImages,
    dndCreateGame,
    dndUpdateGame,
    dndLikeGame,
    voteWordGameTheme,
    voteWordGameSkip,
    getDreamPets,
    getAdoptableDreamPets,
    getAdoptTickets,
    getDreamPet,
    adoptDreamPet,
    generateDreamPet,
    sendDreampetGroupChat,
    petAction,
    getUserActivityMap,
    quickJoinPetBattle,
    aiJoinPetBattle,
    petBattleAction,
    getPetBattleLobbyInfo,
    getPetBattleStats,
    cgCreateRoom,
    cgFetchRooms,
    cgPlayerReady,
    cgJoinGame,
    cgPlayCard,

    sendDreampetBattleChat,
    sendDreampetBossBattleChat,
    getOngoingDreampetBattles,

    makeRequest,
    getDreampetShopAbilites,
    getBattlePoints,
    buyDreampetShopAbility,
    replacePetAbility,
    quickJoinPetBossBattle,
    getPetBossBattleLobbyInfo,
    petBossBattleAction,
    claimPetBossBattleReward,
    getPetBossBattleRewards,
    getPetBossBattleCommunityRoom,
    getExperienceData,
  };
}

export default useAuth;
