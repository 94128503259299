import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onIdTokenChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC-dySujV6FJd1RQSwHSGhEG22fhGLkuyQ",
  authDomain: "dreambox-4fe32.firebaseapp.com",
  projectId: "dreambox-4fe32",
  storageBucket: "dreambox-4fe32.appspot.com",
  messagingSenderId: "367038484385",
  appId: "1:367038484385:web:2084adb1ff213b894d45e0",
  measurementId: "G-ZLKF44MD30"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const db = getFirestore(app);

export { app, analytics, auth, onIdTokenChanged, db }