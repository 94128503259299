import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    id: null,
    created_at: null,
    name: ''
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
      setUser: (_, action) => action.payload,
      userLoggedOut: () => initialState,
      setAvatar: (state, action) => {
        state.avatar = action.payload;
      },
      updateUsername: (state, action) => {
        state.userName = action.payload;
        state.name = action.payload;
      },
      setDiscordUser: (state, action) => {
        console.log("setting discord user");
       
  
        if (action.payload === true) {
          // Add 'discord' to authority if it's not already present
          if (!state.authority.includes("discord")) {
            state.authority.push("discord");
          }
        } else {
          // Remove 'discord' from authority if present
          state.authority = state.authority.filter(auth => auth !== "discord");
        }
      },
    },
  });
  

export const { setUser, setAvatar, updateUsername, setDiscordUser } = userSlice.actions

export default userSlice.reducer
