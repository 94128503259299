import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ConfigProvider } from 'components/ui'
import useDarkMode from 'utils/hooks/useDarkMode'
import { themeConfig } from 'configs/theme.config'
import useAuth from 'utils/hooks/useAuth'

const APP_VERSION = 'v1.0'; // Update this with each new deployment

const Theme = (props) => {
    const { signOut } = useAuth()
    const theme = useSelector((state) => state.theme)
    const locale = useSelector((state) => state.locale.currentLang)
    const [isDark] = useDarkMode()

    const currentTheme = {
        mode: isDark ? 'dark' : 'light',
        ...themeConfig,
        ...theme,
        ...{ locale },
    }

    useEffect(() => {
        // On app start...
        if (localStorage.getItem('app_version') !== APP_VERSION) {
            // Store the new version in local storage
            localStorage.setItem('app_version', APP_VERSION);
            
            // Logout the user
            signOut();

            // Reload the page
            window.location.reload();
        }
    }, []);

    return (
        <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>
    )
}

export default Theme
